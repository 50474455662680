import React, { useEffect, useRef } from 'react'
import { Highlight } from 'react-instantsearch-dom'
import Link from '../Link/Link'

interface HitProps {
  hit: any
  active?: boolean
  selected?: boolean
  onMouseEnter?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
  onMouseLeave?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
}

const Hit: React.FC<HitProps> = ({hit, active, selected, onMouseEnter, onMouseLeave}) => {
  const ref = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if(selected) {
      ref.current?.click()
    }
  }, [selected])

  return (
    <Link
      className={`hit ${active ? 'hit--active' : ''}`}
      to={hit.slug}
      innerRef={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <h6 className="hit__title">
        {hit.categoryTitle ?
          <>
            <Highlight hit={hit} attribute="categoryTitle" tagName="mark" />&nbsp;&gt;&nbsp;
          </>
          : ''
        }
        {hit.subCategoryTitle ?
          <>
            <Highlight hit={hit} attribute="subCategoryTitle" tagName="mark" />&nbsp;&gt;&nbsp;
          </>
          : ''
        }
        <Highlight hit={hit} attribute="title" tagName="mark" />
      </h6>
      <p className="hit__subtitle">
        <Highlight hit={hit} attribute="subtitle" tagName="mark" />
      </p>
      {/* <p>{hit.excerpt}</p> */}
    </Link>
  )
}

export default Hit
