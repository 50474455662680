import React from 'react'

interface BackdropProps {
  show: boolean
  onClick: () => void
}

const Backdrop: React.FC<BackdropProps> = ({show, onClick}) =>
  <div
    className={`backdrop ${show ? 'backdrop--active': ''}`}
    onClick={onClick}
  />

export default Backdrop
