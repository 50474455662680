import React from 'react'
import Search from '../Search/Search'

import xIcon from './x.svg'

import './styles.scss'

interface MobileSearchProps {
  open: boolean
  onClose?: () => void
}

const MobileSearch: React.FC<MobileSearchProps> = ({open, onClose}) => {


  return (
    <div className={`mobile-search ${open ? 'mobile-search--active' : ''}`}>
      <div className="mobile-search-bg"/>
      <button onClick={onClose} className="mobile-search__btn-close">
        <img src={xIcon} alt="Close icon"/>
      </button>
      {
        open &&
        <Search
          autoFocus={true}
        />
      }
    </div>
  )
}

export default MobileSearch
