import { Globe } from '@kushki/suka-react-system-icons'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import React, { useContext, useState } from 'react'
import CountryContext from '../../context/CountryContext'
import LocaleContext from '../../context/LocaleContext'
import locales, { Locale } from '../../data/locales'
import useOnClickOutside from '../../hooks/useClickOutside'

import './styles.scss'


const LanguageSelector: React.FC = () => {
  const ref = React.createRef<HTMLDivElement>()
  const [dropdownActive, setDropdownActive] = useState(false)

  const currentLocale = useContext(LocaleContext)
  const selectedLocale = locales.filter(locale=> locale.code === currentLocale)[0]
  const restOfLocales = locales.filter(locale=> locale.code !== currentLocale)

  const currentCountry = useContext(CountryContext)

  const saveLocale = (locale: Locale) => {
    localStorage.setItem('preferredLanguage', locale.code)
  }

  const getLocalizedPath = (selectedLoc: Locale, locale: Locale, path: string) => {
    if(path.includes(`/${selectedLoc.code}`)) { // Removes current locale from path
      path = path.replace(`/${selectedLoc.code}`, '')
    }

    if(locale.default) {
      return path
    } else {
      if (path === '/' || path === ''){ // If we're on root without a country selected
        return `/${locale.code}/`
      } else if (path === `/${currentCountry}`) { // Si we're on root with a country selected
        return `/${currentCountry}/${locale.code}/`
      } else {
        return path.replace(`/${currentCountry}/`, `/${currentCountry}/${locale.code}/`)
      }
    }
  }

  useOnClickOutside(ref, () => setDropdownActive(false))

  return (
    <Location>
      {({ location }) =>
      <div className="language-selector">
        <div className="dropdown" ref={ref}>
          <button
            className={`dropdown__toggle ${dropdownActive ? 'active' :  ''}`}
            id="languageMenu"
            onClick={() => setDropdownActive(!dropdownActive)}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={dropdownActive}
          >
            <Globe className="mr-1 header-link__icon" height={16} width={16} />
            {selectedLocale.code.toUpperCase()}
          </button>
          <div
            className={`dropdown-menu  ${dropdownActive ? 'open' : ''}`}
          >
            {
              restOfLocales.map(locale =>
                <Link
                  to={getLocalizedPath(selectedLocale, locale, location.pathname)}
                  key={locale.code}
                  onClick={() => saveLocale(locale)}
                  className={`dropdown__link`}
                >
                  {locale.name} ({locale.code.toUpperCase()})
                </Link>
              )
            }
          </div>
        </div>
      </div>
      }
    </Location>
  )
}

export default LanguageSelector
