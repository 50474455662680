import { Activity, BookOpen, Support } from '@kushki/suka-react-system-icons'
import React from 'react'

import Search from '../Search/Search'

import { FormattedMessage } from 'react-intl'

import './styles.scss'

const Header: React.FC = () => (
  <div className="header">
    <div className="header-bg"/>
    <div className="d-flex flex-grow-1 align-items-center ">
      <a className="header-link" href="https://api-docs.kushkipagos.com/api-reference" target="_blank">
        <BookOpen className="header-link__icon" />
        <FormattedMessage id="header.reference" />
      </a>
      <a className="header-link" href="https://soporte.kushkipagos.com/" target="_blank">
        <Support className="header-link__icon" />
        <FormattedMessage id="header.support" />
      </a>
      <a className="header-link" href="https://status.kushkipagos.com/" target="_blank">
        <Activity className="header-link__icon" />
        <FormattedMessage id="header.service_status" />
      </a>
    </div>
    <Search/>

  </div>
)

export default Header
