export type LocaleTypes = 'es' | 'en';

export interface Locale {
  name: string;
  code: LocaleTypes;
  path: string;
  default?: boolean;
  dateStringFormat: string;
}

const locales: Locale[] = [
  {
    code: 'en',
    dateStringFormat: 'MMMM DD, YYYY',
    name: 'English',
    path: 'en',
  },
  {
    code: 'es',
    dateStringFormat: 'DD MMMM YYYY',
    default: true,
    name: 'Español',
    path: 'es',
  },
];

export default locales;
