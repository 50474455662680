import React, { ReactNode, useRef } from 'react'
import useAccordion from '../../hooks/useAccordion'

import chevronIcon from './chevron-right.svg'

import './styles.scss'

interface AccordionProps {
  children: ReactNode
  title: string | ReactNode
  open?: boolean
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  title,
  open = false
}) => {

  const content = useRef<HTMLDivElement>(null)

  const {toggleAccordion, open: isOpen, height} = useAccordion(content, open ? 'open' : 'closed')


  return (
    <div className="accordion-wrapper">
      <button className={`accordion-toggle ${isOpen ? 'accordion-toggle--active' : ''}`} onClick={toggleAccordion}>
        {title}

        <img src={chevronIcon} className="accordion-chevron"/>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion-content"
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
