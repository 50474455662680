import { useLocation } from '@reach/router'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useState } from 'react'
import CountryContext from '../../context/CountryContext'
import LocaleContext from '../../context/LocaleContext'
import useOnClickOutside from '../../hooks/useClickOutside'

import './styles.scss'

const CountrySelection: React.FC = () => {
  const location = useLocation()
  const countryCode = useContext(CountryContext)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const ref = React.createRef<HTMLDivElement>()

  const query = useStaticQuery(graphql`
    query Countries {
      countries: allContentfulCountry(sort: {fields: name}) {
        nodes {
          code
          name
          node_locale
          flag {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const locale = useContext(LocaleContext)

  const countries = query.countries.nodes.filter((country: any) => country.node_locale === locale)

  const selectedCountry = countries.find((country: any) => country.code === countryCode)

  const unselectedCountries =  countries.filter((country: any) => country.code !== countryCode)

  const strippedLink = location.pathname.substring(3)

  useOnClickOutside(ref, () => setDropdownOpen(false))

  return (
    <div className="country-selection" ref={ref}>
      <div className="dropdown">
        <button
          className={`dropdown__toggle ${dropdownOpen ? 'active' :  ''}`}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <img src={selectedCountry.flag.localFile.publicURL} height="16px" width="16px" className="mr-2" />{selectedCountry.name}
        </button>

        <div  className={`dropdown-menu ${dropdownOpen ? 'open': ''}`}>
          {
            unselectedCountries.map((country: any) => (
              <Link
                key={country.code}
                className="dropdown__link"
                to={`/${country.code}${strippedLink}`}
              >
                <img src={country.flag.localFile.publicURL} height="16px" width="16px" className="mr-2" />{country.name}
              </Link>
            ))
          }
        </div>
      </div>

    </div>
  )
}

export default CountrySelection
