import React from 'react'
import Link from '../Link/Link'
import BurgerMenu from './BurgerMenu'

import logoImg from './logo.svg'
import searchImg from './search.svg'

import './styles.scss'

interface MobileHeaderProps {
  onOpenSidebar: () => void
  onOpenSearch: () => void
}

const MobileHeader: React.FC<MobileHeaderProps> = ({onOpenSidebar, onOpenSearch}) => {
  const openMenu = () => {
    onOpenSidebar()
  }

  const openSearch = () => {
    onOpenSearch()
  }

  return (
    <div className="mobile-header">
      <div className="mobile-header-bg"/>
      <BurgerMenu onClick={openMenu}/>
      <div className="mobile-header__logo">
        <Link to="/">
          <img src={logoImg} alt="brand" />
        </Link>
      </div>
      <button className="search-btn" onClick={openSearch}>
        <img
          src={searchImg}
        />
      </button>

    </div>
  )
}

export default MobileHeader
