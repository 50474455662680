import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CountryContext from '../../context/CountryContext'
import LocaleContext from '../../context/LocaleContext'
import Accordion from '../Accordion/Accordion'
import Link from '../Link/Link'

import homeIcon from './home.svg'

import './styles.scss'

interface NavigationCategory {
  slug: string
  title: string
  subtitle: string
  subCategories: NavigationCategory[]
  articles: NavigationArticle[] | null
  icon?: {
    localFile: {
      publicURL: string
    }
  }
}

interface NavigationArticle {
  slug: string
  title: string
  navigationTitle?: string
  restrictedCountries: Array<{
    code: string
  }>
}


const Navigation: React.FC = () => {
  const query = useStaticQuery(
    graphql`
      query Navigation {
        navigation: allContentfulNavigation {
          nodes {
            locale: node_locale
            categories: topCategories {
              slug
              title
              subtitle
              icon {
                localFile {
                  publicURL
                }
              }
              articles {
                slug
                navigationTitle
                title
                restrictedCountries {
                  code
                }
              }
              subCategories {
                articles {
                  slug
                  title
                  navigationTitle
                  restrictedCountries {
                    code
                  }
                }
                slug
                title
              }
            }
          }
        }
      }
    `
  )

  const location = useLocation()

  const isCategoryActive = (slug: string) => {
    return location.pathname.indexOf(slug) > 0
  }

  const country = useContext(CountryContext)

  const localeCode = useContext(LocaleContext)

  const mainNavigationCategories: NavigationCategory[] =
    query.navigation.nodes
      .filter((node: any) => node.locale === localeCode)[0]
      .categories

  const shouldCategoryDisplay = (category: NavigationCategory): boolean => {
    if(!category) {
      return false
    }

    const mainDisplay: boolean = (
      category.articles !== null &&
      category.articles
        .reduce<boolean>(
          (acc, article) => {
            if(article.restrictedCountries !== null) {
              return !article.restrictedCountries
              .map(rc => rc.code)
              .includes(country) ||
              acc
            }

            return acc || true
          }
        , false)
    )

    if(category.subCategories) {
      return mainDisplay || category.subCategories.reduce<boolean>(
        (acc, subCategory) => {
          return acc || shouldCategoryDisplay(subCategory)
        }
        , false)
    } else {
      return mainDisplay
    }
  }

  const shouldArticleDisplay = (article: NavigationArticle): boolean => {
    if(article.restrictedCountries === null) {
      return true
    }

    return !article.restrictedCountries
      .map(rc => rc.code)
      .includes(country)
  }

  return (
    <ul className="navigation">
      <li className="navigation__category navigation__category--home">
        <Link
          to="/"
          className="navigation__link"
          activeClassName="navigation__link--active"
        >

          <img className="navigation__category__icon" src={homeIcon} />
          <div className={`navigation__category__titles`}>
            <span
              className="navigation__category__title"
            >
                <FormattedMessage id="home" />
            </span>
          </div>
        </Link>
      </li>

      {
        mainNavigationCategories.map(category =>
          shouldCategoryDisplay(category) &&
            <React.Fragment key={category.slug}>
              <li className="navigation__category">
                <Accordion
                  title={
                    <>
                    <img className="navigation__category__icon" src={category.icon?.localFile.publicURL} />
                    <div className={`navigation__category__titles ${isCategoryActive(category.slug) ? 'navigation__category__titles--active' : ''}`}>
                      <span
                        className="navigation__category__title"
                      >
                          {category.title}
                      </span>
                      <span className="navigation__category__subtitle">
                        {category.subtitle}
                      </span>
                    </div>
                    </>
                  }
                  open={isCategoryActive(category.slug)}
                >
                  <ul className="navigation__category__articles">
                      {
                        category.articles && category.articles.map(article =>
                          shouldArticleDisplay(article) &&
                          <li className="navigation__article" key={`${category.slug}-${article.slug}`}>
                            <Link
                              to={`/${category.slug}/${article.slug}`}
                              className="navigation__link"
                              activeClassName="navigation__link--active"
                            >
                              { article.navigationTitle ? article.navigationTitle : article.title }
                            </Link>
                          </li>
                        )
                      }
                    </ul>

                    {
                      category.subCategories &&
                      category.subCategories.map(subCategory =>
                          shouldCategoryDisplay(subCategory) &&
                            <div key={`${category.slug}-${subCategory.slug}`} className="navigation__subcategory">
                              <span className="navigation__subcategory__title">{subCategory.title}</span>
                              <ul className="navigation__subcategory__articles">
                              {
                                subCategory.articles && subCategory.articles.map(article =>
                                  shouldArticleDisplay(article) &&
                                  <li key={`${category.slug}-${subCategory.slug}-${article.slug}`} className="navigation__article">
                                    <Link
                                      to={`/${category.slug}/${subCategory.slug}/${article.slug}`}
                                      className="navigation__link"
                                      activeClassName="navigation__link--active"
                                    >
                                      { article.navigationTitle ? article.navigationTitle : article.title }
                                    </Link>
                                  </li>

                                )
                              }
                              </ul>
                            </div>

                      )
                    }
                </Accordion>
              </li>
            </React.Fragment>

        )
      }
    </ul>
  )
}

export default Navigation
