import { LogIn } from '@kushki/suka-react-system-icons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import CountrySelection from '../CountrySelection/CountrySelection'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Link from '../Link/Link'
import Navigation from '../Navigation/Navigation'

import logInImg from './log-in.svg'
import logoImg from './logo.svg'

import './styles.scss'

const Sidebar: React.FC= () => (
  <div className="sidebar">
    <div className="sidebar__logo">
      <Link to="/">
        <img src={logoImg} className="sidebar__logo__img" alt="brand" />
      </Link>
    </div>

    <div className="d-flex">
      <CountrySelection/>

      <LanguageSelector />
    </div>

    <Navigation/>

    <div className="sidebar-bottom-links">
      <a className="sidebar-bottom-links__link" href="https://uat-console.kushkipagos.com/" target="_blank">
        <LogIn size={16} className="sidebar-bottom-links__link__icon mr-2" />
        <FormattedMessage id="sidebar.log_in"/>
      </a>
    </div>
  </div>
)

export default Sidebar
