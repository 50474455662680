import React from 'react';

import menuImg from './menu.svg';

interface BurgerProps {
  onClick: () => void;
};

const BurgerMenu: React.FC<BurgerProps> = ({onClick}) => (
  <img
    src={menuImg}
    alt=""
    className="burger-menu-btn"
    onClick={onClick}
  />
);


export default BurgerMenu;
