import React, { useEffect, useState } from 'react'

const useKeyPress = (targetKey: string | number) => {
    const [keyPressed, setKeyPressed] = useState(false)

    function downHandler({ key }: any) {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }

    const upHandler = ({ key }: any) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }

    useEffect(() => {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)

      return () => {
        window.removeEventListener('keydown', downHandler)
        window.removeEventListener('keyup', upHandler)
      }
    })

    return keyPressed
  }

  export default useKeyPress
