import React, { useEffect } from 'react'

const useOnClickOutside = (ref: any, handler:() => void, events = [`mousedown`, `touchstart`]) => {
  const detectClickOutside = (event: any) =>
    !ref.current.contains(event.target) && handler()

  useEffect(() => {
    for (const event of events) { document.addEventListener(event, detectClickOutside) }
    return () => {
      for (const event of events) {
        document.removeEventListener(event, detectClickOutside)
      }
    }
  })
}

export default useOnClickOutside
