import { useEffect, useState } from 'react'

const useAccordion = (
  ref: any,
  initialState?: 'open' | 'closed'
) => {
  const [open, setOpen] = useState(initialState === 'open' ? true : false)
  const [height, setHeight] = useState(initialState === 'open' ? 'inherit': '0px')

  const toggleAccordion: () => void = () => {
    setOpen(!open)
    setHeight(
     !open ? '0px' : `${ref.current.scrollHeight}px`
    )
  }

  useEffect(() => {
    setHeight(
      !open ? '0px' : `${ref.current.scrollHeight}px`
    )
  })

  return {toggleAccordion, open, height}
}

export default useAccordion
