import React from 'react'

import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import CountryContext from '../../context/CountryContext';
import LocaleContext from '../../context/LocaleContext';
import locales from '../../data/locales';

const Link: React.FC<Omit<GatsbyLinkProps<{}>, 'ref'>> = ({ to, ...props }) => (
  <CountryContext.Consumer>
    {country =>
      <LocaleContext.Consumer>
        { localeCode => {
          const locale = locales.filter(l => l.code === localeCode)[0];

          const path = locale.default ? `/${country}${to}` : `/${country}/${locale.code}${to}`;

          return <GatsbyLink {...props} to={path} />
        }}
      </LocaleContext.Consumer>
    }
  </CountryContext.Consumer>
)

export default Link;
