import { Activity, BookOpen, LogIn, Support } from '@kushki/suka-react-system-icons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import CountrySelection from '../CountrySelection/CountrySelection'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Navigation from '../Navigation/Navigation'
import './styles.scss'
interface MobileSidebarProps {
  open: boolean
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({open}) => (
  <div className={`mobile-sidebar ${open ? 'mobile-sidebar--active' : ''}`}>
    <div className="d-flex">
      <CountrySelection/>

      <LanguageSelector />
    </div>

    <Navigation/>

    <div className="sidebar-bottom-links">
      <a className="sidebar-bottom-links__link" href="https://api-docs.kushkipagos.com/api-reference" target="_blank">
        <BookOpen size={16} className="sidebar-bottom-links__link__icon" /> <FormattedMessage id="header.reference"/>
      </a>
      <a className="sidebar-bottom-links__link" href="https://soporte.kushkipagos.com/" target="_blank">
        <Support size={16} className="sidebar-bottom-links__link__icon" /> <FormattedMessage id="header.support"/>
      </a>
      <a className="sidebar-bottom-links__link" href="https://api-docs.kushkipagos.com/api-reference" target="_blank">
        <Activity size={16} className="sidebar-bottom-links__link__icon" /> <FormattedMessage id="header.service_status"/>
      </a>
      <a className="sidebar-bottom-links__link" href="https://uat-console.kushkipagos.com/" target="_blank">
        <LogIn size={16} className="sidebar-bottom-links__link__icon" /> <FormattedMessage id="sidebar.log_in"/>
      </a>
    </div>
  </div>
)

export default MobileSidebar
