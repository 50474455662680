import algoliasearch from 'algoliasearch/lite'
import React, { useContext, useMemo, useState } from 'react'
import { connectStateResults, InstantSearch, PoweredBy, SearchBox } from 'react-instantsearch-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import CountryContext from '../../context/CountryContext'
import LocaleContext from '../../context/LocaleContext'
import useOnClickOutside from '../../hooks/useClickOutside'
import Hits from './Hits'
import './styles.scss'


const Results = connectStateResults(
  ({ searching, searchState: state, searchResults: res }) => {
    if(searching || res?.nbHits === 0) {
      return (
        <div className="results-wrapper">
          {
            searching && `Buscando...` ||
            (res?.nbHits === 0 &&
              <>
                <FormattedMessage id="search.no_results" values={{query: <b>"{state.query}"</b>}} />
              </>
            )
          }
        </div>
      )
    } else {
      return <></>
    }
  }
)

const KbdShortcut: React.FC = ({children}) =>
  <kbd>{children}</kbd>

interface SearchProps {
  collapse?: boolean;
  autoFocus?: boolean
}

const Search: React.FC<SearchProps> = ({ collapse = true, autoFocus = false }) => {
  const ref = React.createRef<HTMLDivElement>()
  const [currentQuery, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)
  // useMemo prevents the searchClient from being recreated on every render.
  // Avoids unnecessary XHR requests (see https://tinyurl.com/yyj93r2s).
  const searchClient = useMemo(
    () =>
      algoliasearch(
        `${process.env.GATSBY_ALGOLIA_APP_ID}`,
        `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
      ),
    []
  )

  const countryCode = useContext(CountryContext)
  const localeCode = useContext(LocaleContext)

  const onKeyDown = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) { // Up or down keys
      event.preventDefault()
    }
  }

  useOnClickOutside(ref, () => setFocus(false))

  const intl = useIntl();

  return (
    <div ref={ref} className="search-wrapper">
      <InstantSearch
        searchClient={searchClient}
        indexName={`Docs ${countryCode} ${localeCode}`}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox
          autoFocus={autoFocus}
          className="search-box"
          translations={{
            placeholder: intl.formatMessage({id: 'header.search'}),
          }}
          onFocus={() => setFocus(true)}
          onKeyDown={onKeyDown}
        />

        {
          !focus &&
          <KbdShortcut>
            s
          </KbdShortcut>
        }

        <div className="hits-wrapper">
          {
            currentQuery.length > 0 && focus &&
            <>
              <Results/>
              <Hits/>
              <PoweredBy/>
            </>
          }

        </div>
      </InstantSearch>
    </div>
  )
}

export default Search
