import browserLang from 'browser-lang';
import flatten from 'flat';

import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import CountryContext from '../../context/CountryContext';
import LocaleContext from '../../context/LocaleContext';
import locales, { LocaleTypes } from '../../data/locales';
import en from '../../i18n/en.json';
import es from '../../i18n/es.json';

import Header from '../Header/Header';
import Backdrop from '../MobileHeader/Backdrop';
import MobileHeader from '../MobileHeader/MobileHeader';
import MobileSearch from '../MobileSearch/MobileSearch';
import MobileSidebar from '../Sidebar/MobileSidebar';
import Sidebar from '../Sidebar/Sidebar';

import '../../styles/index.scss';
import './styles.scss';

const messages = { es, en }
interface LayoutProps {
  children: React.ReactNode
  country?: string
  locale: string
  location: Location
}

const getPreferredLanguage: any = () => {
  let preferredLanguage = typeof window !== 'undefined' && window.localStorage.getItem('preferredLanguage');

  if(preferredLanguage === null) {
    preferredLanguage = `${browserLang({
      fallback: 'es',
      languages: locales.map(locale => locale.code),
    })}`;

    localStorage.setItem('preferredLanguage', preferredLanguage);
  }

  return preferredLanguage;
}

const Layout: React.FC<LayoutProps> = ({ children, country, locale, location }) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
  
  const toggleMobileSidebar = () => {
    setMobileSidebarOpen(!mobileSidebarOpen)
  }
  
  const toggleMobileSearch = () => {
    setMobileSearchOpen(!mobileSearchOpen)
  }
  
  // tslint:disable-next-line: variable-name
  const _locale = locale ? locale : 'es';

  const preferredLanguage = getPreferredLanguage();

  useEffect(() => {
    if (_locale !== preferredLanguage) {
      const preferredLocale = locales.filter(l => l.code === preferredLanguage)[0];
      
      let path = location.pathname;
      if(path.includes(`/${_locale}`)) {
        path = path.replace(`/${_locale}`, '');
      }

      const localizedPath = preferredLocale.default
        ? path
        : path.replace(`/${country}/`, `/${country}/${preferredLocale.code}/`);

      navigate(localizedPath)
    }
  }, [])

  return (
    <CountryContext.Provider value={country ? country : 'cl'}>
      <LocaleContext.Provider value={_locale}>
        <IntlProvider locale={_locale} messages={flatten(messages[_locale])}>
          <div className="docs">
            <Sidebar/>

            <MobileSidebar
              open={mobileSidebarOpen}
            />

            <MobileSearch
              open={mobileSearchOpen}
              onClose={toggleMobileSearch}
            />

            <Backdrop
              show={mobileSidebarOpen || mobileSearchOpen}
              onClick={toggleMobileSidebar}
            />

            <div className="content-wrapper">
              <Header />
              <MobileHeader
                onOpenSidebar={toggleMobileSidebar}
                onOpenSearch={toggleMobileSearch}
              />
              <main>{children}</main>
            </div>
          </div>
        </IntlProvider>
      </LocaleContext.Provider>
    </CountryContext.Provider>
  )
}

export default Layout
