import React, { useEffect, useState } from 'react'

import { Hit as HitType, HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'

import useKeyPress from '../../hooks/useKeyPress'

import Hit from './Hit'

const Hits: React.StatelessComponent<HitsProvided<HitType>> = ({hits}) => {
  const [selected, setSelected] = useState<number | undefined>(undefined)
  const downPress = useKeyPress('ArrowDown')
  const upPress = useKeyPress('ArrowUp')
  const enterPress = useKeyPress('Enter')
  const [cursor, setCursor] = useState(0)
  const [hovered, setHovered] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (hits.length && downPress) {
      setCursor(prevState =>
        prevState < hits.length - 1 ? prevState + 1 : prevState
      )
    }
  }, [downPress])
  useEffect(() => {
    if (hits.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState))
    }
  }, [upPress])
  useEffect(() => {
    if (hits.length && enterPress) {
      setSelected(cursor)
    }
  }, [cursor, enterPress])
  useEffect(() => {
    if (hits.length && hovered) {
      setCursor(hovered)
    }
  }, [hovered])

  return (
    <ul>
      {
        hits.map((hit, index) =>
          <li key={hit.objectID}>
            <Hit
              active={cursor === index}
              hit={hit}
              selected={selected === index}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(undefined)}
            />
          </li>
        )
      }
    </ul>
  )
  }

const ConnectedHits = connectHits(Hits)

export default ConnectedHits
